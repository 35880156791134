/**
 * Add gtm on route change
 * https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js
 */

import { useEffect } from "react";
import { useRouter } from "next/router";
import * as gtm from "@/lib/gtm";

const GoogleTagManager = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    gtm.pageView(router.asPath);
  }, [router.asPath]);

  return children;
};

export default GoogleTagManager;
