// @refresh reset
// https://next.chakra-ui.com/docs/theming/component-style

// multipart component
// name must be Sentence case
const Tabs = {
  parts: ["tabs", "tablist", "tab", "tabpanel"],
  baseStyle: {
    tabs: {
      // bg: "thiel.500",
    },
    tablist: {
      borderBottomWidth: "1px",
      borderBottomColor: "textblack",
    },
    tab: {
      // fontWeight: "bold",
      // textTransform: "uppercase",
    },
    tabpanel: {
      px: 0,
    },
  },
  sizes: {
    sm: {
      tab: {
        fontSize: "rg",
        px: 2,
        py: 2,
      },
    },
  },
  variants: {
    profile: {
      tablist: {
        borderBottomWidth: "2px",
        borderBottomColor: "blackAlpha.200",
        justifyContent: "space-between",
      },
      tab: {
        borderBottom: "2px solid",
        borderBottomColor: "transparent",
        color: "blackAlpha.400",
        fontWeight: "bold",
        marginBottom: "-2px",
        textTransform: "uppercase",
        px: 2,
        py: 3,
        _focus: {
          boxShadow: "none",
          borderBottomColor: "black",
        },
        _selected: {
          color: "black",
          borderBottomColor: "black",
        },
        _disabled: {
          color: "gray.500",
        },
      },
    },
    mainmenu: {
      tablist: {
        position: "fixed",
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        bottom: 0,
        px: 0,
        py: 0,
        bg: "thiel.1200",
      },
      tab: {
        // can't do layerStyles?
        // layerStyle: "navHeight",
        color: "black",
        fontSize: "15px",
        px: { base: 4, lg: 9 },
        h: { base: "54px" },
        m: 0,
        bg: "thiel.1200",
        width: "full",
        borderBottom: "1px solid",
        borderColor: "grey.50",
        justifyContent: "flex-start",
        _hover: { bg: "thiel.1100" },
        _selected: { bg: "thiel.1100" },
        _focus: {
          boxShadow: "initial",
          outline: "none",
        },
        _last: {
          color: "blackAlpha.600",
          _hover: {
            bg: "transparent",
            color: "black",
          },
        },
      },
      // doesn't render anything?
      // tabpanels: {
      //   bg: "salmon",
      // },
      tabpanel: {
        // layerStyle: "spaceMdT",
        pt: { base: 6, lg: 8, xl: 16 },
        px: { base: 6, lg: 8, xl: 16 },
        pb: 0,
      },
    },
  },
  defaultProps: {
    tab: {
      size: "sm",
      variant: "profile",
    },
  },
};

export default Tabs;
