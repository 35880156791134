/*
 * Configuration file
 * For application wide access to environment variables and shared site data
 *
 * Sensitive data should be stored in a .env file
 */

module.exports = {
  NODE_ENV_IS_PRODUCTION: process.env.NODE_ENV === "production",
  // NEXT_PUBLIC_CLEARED_ENVIRONMENT is used to differentiate between staging and production. We
  // can't use only NODE_ENV because staging deployments run with NODE_ENV=production.
  IS_PRODUCTION: process.env.NEXT_PUBLIC_CLEARED_ENVIRONMENT === "production",

  siteUrl: process.env.NEXT_PUBLIC_SITE_URL,
  siteEmail: process.env.NEXT_PUBLIC_SITE_EMAIL,
  supportEmail: process.env.NEXT_PUBLIC_SUPPORT_EMAIL,
  serverUrl: process.env.NEXT_PUBLIC_SERVER_URL,
  marketingSiteUrl: process.env.NEXT_PUBLIC_MARKETING_SITE_URL,
  datoUrl: process.env.NEXT_PUBLIC_DATO_URL,
  datoApiToken: process.env.NEXT_DATO_API_TOKEN,
  gtmId: process.env.NEXT_PUBLIC_GTM_TRACKING_ID,
  fbPixelId: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
  stripePublishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  peatnutAllergyQuizSlug: "peanut-allergy-quiz",
  peanutAllergyQuizV2Slug: "peanut-allergy-quiz-v2",
  ashtmaQuizSlug: "asthma-mif",
  environmentAllergyQuizSlug: "environmental-allergy-quiz",
  immunotherapyV3QuizSlug: "immunotherapy-quiz-flow-3",
  initialUtmSourceCookieName: "initial_utm_source",
  portalInitialUtmSouceLocalStorageName: "portal_initial_utm_source",
  adminUserEmails: [
    "barryph@protonmail.com",
    "nurse@getcleared.com",
    "steph@getcleared.com",
    "drgupta@getcleared.com",
    "skennedy@partizanhealth.com",
    "payelg1@gmail.com",
    "zach.hay@hey.com",
  ],
};
