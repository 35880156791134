import {
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  USER_UPDATE_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  LOGOUT,
  DELETE_ACCOUNT_SUCCESS,
  SET_PROFILE_STATUS,
} from "./actions";

const initialState = {
  user: null,
  // In the case user is null `hasFechedUser` is used to deduce if the user is unauthenticated or
  // if the the initial get user request to check has not yet completed
  hasFetchedUser: false,
};

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
    case FETCH_USER_SUCCESS:
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        user: action.user,
        hasFetchedUser: true,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        user: null,
        hasFetchedUser: true,
      };
    case LOGOUT:
    case DELETE_ACCOUNT_SUCCESS:
      return {
        user: null,
      };
    case SET_PROFILE_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          profileStatus: action.status,
        },
      };
    default:
      return state;
  }
}
