// @refresh reset
// https://next.chakra-ui.com/docs/theming/customize-theme#scaling-out-your-project
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/index.ts

import { extendTheme } from "@chakra-ui/react";

import components from "./components";
import foundations from "./foundations";
import styles from "./styles";
import layerStyles from "./layerStyles";
import textStyles from "./textStyles";

const overrides = {
  styles,
  ...foundations,
  textStyles,
  layerStyles,
  components,
};

export default extendTheme(overrides);
