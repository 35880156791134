import config from "@/config";
import { setLocalStorageIfNotSet } from "@/lib/setLocalStorageIfNotSet";
import { useRouter } from "next/router";
import { useEffect } from "react";

const useSavePortalInitialUtmSource = () => {
  const router = useRouter();

  const { utm_source: utmSourceQueryParam } = router.query;

  useEffect(() => {
    try {
      const utmSource = utmSourceQueryParam?.toString();
      if (utmSource) {
        setLocalStorageIfNotSet(
          config.portalInitialUtmSouceLocalStorageName,
          utmSource
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.toString());
    }
  }, [utmSourceQueryParam]);
};

export default useSavePortalInitialUtmSource;
