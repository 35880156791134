// @refresh reset
// https://github.com/chakra-ui/chakra-ui/tree/develop/packages/theme/src

const styles = {
  global: {
    body: {
      fontFamily: "body",
      bg: "white",
      // color mode example for later…
      // color: mode("gray.800", "whiteAlpha.900")(props),
      color: "gray.800", // #1A202C
      fontSize: "base",
      lineHeight: "base",
      transition: "background-color 0.2s",
    },
    a: {
      color: "uranianblue.900",
      textDecoration: "none",
      _hover: {
        textDecoration: "underline",
      },
    },
    "*::placeholder": {
      color: "gray.400",
    },
    "*, *::before, &::after": {
      borderColor: "blackAlpha.100",
      wordWrap: "break-word",
    },
    // should work for any selector on a page but DOES NOT
    // https://github.com/chakra-ui/chakra-ui/issues/1561#issuecomment-671029136
    // "#__next": {
    //   backgroundColor: "pink",
    //   height: "100%",
    //   width: "100%",
    // },

    // SCREEN HEIGHT used in LayoutMain
    ".all-screen-h": {
      height: "var(--screen-height)",
    },

    // CHAKRA THINGS
    ".chakra-form__required-indicator": {
      color: "currentColor !important",
      marginLeft: "1px !important",
    },
    // ".chakra-menu__menu-button": {
    //   paddingLeft: "0.5em",
    //   paddingRight: "0.5em",
    // },
    // ".chakra-menu__menu-button span:first-of-type": {
    //   display: "none !important",
    // },
    // ".chakra-stack > *": {
    //   w: "full",
    // },

    // UTILS
    ".bg-gray": {
      backgroundColor: { base: "white", lg: "grey.10" },
    },
    // react-transition-group fade
    ".rtg-fade-enter": {
      opacity: 0,
    },
    ".rtg-fade-exit": {
      opacity: 1,
    },
    ".rtg-fade-enter-active": {
      opacity: 1,
    },
    ".rtg-fade-exit-active": {
      opacity: 0,
    },
    ".rtg-fade-enter-active, .rtg-fade-exit-active": {
      transition: "opacity 500ms",
    },
    // datepicker themeing
    // unfortunately this has to be global css
    // https://nextjs.org/docs/basic-features/built-in-css-support
    // https://github.com/Hacker0x01/react-datepicker
    ".datePicker": {
      pos: "relative",
      mx: "auto",
      w: { md: "50%" },
    },
    ".react-datepicker-wrapper": {
      w: "full",
    },
    ".datePicker input": {
      color: "black",
    },
    ".react-datepicker": {
      bg: "#fff",
      border: "1px solid",
      borderColor: "thiel.700",
      borderRadius: "md",
      color: "thiel.800",
      d: "inline-block",
      fontFamily: "body",
      fontSize: "0.8rem",
      pos: "relative",
    },
    ".react-datepicker__header": {
      bg: "thiel.100",
      borderColor: "thiel.800",
    },
    ".react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header": {
      color: "thiel.800",
      fontWeight: "600",
    },
    // navigation arrows
    ".react-datepicker__navigation": {
      "--size": "8px",
      border: "0.4rem solid transparent",
      top: "13px",
      height: "var(--size)",
      width: "var(--size)",
    },
    ".react-datepicker__navigation:focus": {
      outline: "none",
    },
    ".react-datepicker__navigation--next": {
      right: "var(--size)",
      borderLeftColor: "thiel.800",
    },
    ".react-datepicker__navigation--previous": {
      left: "var(--size)",
      borderRightColor: "thiel.800",
    },
    ".react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name": {
      color: "thiel.800",
    },
    ".react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow": {
      borderTopColor: "thiel.600",
      top: "7px",
      // border: "8px solid transparent",
      borderWidth: "0.35rem",
    },
    ".react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before": {
      borderWidth: "8px",
      left: "-8px",
      borderBottomColor: "#aeaeae",
    },
    // days
    ".react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected": {
      bg: "tealactive.500",
      color: "white",
    },
    ".react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover": {
      borderRadius: "0.3rem",
      bg: "thiel.300",
    },
    // triangle
    ".react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle": {
      borderBottomColor: "thiel.100",
    },
    ".react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before": {
      borderBottomColor: "thiel.700",
    },
    // nont-html dropdowns
    ".react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown": {
      bg: "thiel.200",
      borderColor: "thiel.700",
    },
    ".react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover": {
      bg: "thiel.500",
    },
    // html selects
    ".react-datepicker__header__dropdown--select": {
      pt: 2,
      pb: 1,
    },
    ".react-datepicker__month-dropdown-container--select, .react-datepicker__year-dropdown-container--select": {
      border: "1px solid",
      borderColor: "transparent",
    },
    ".react-datepicker__year-dropdown-container--select": {
      ml: 2,
    },
    ".react-datepicker__month-select, .react-datepicker__year-select": {
      bg: "transparent",
    },
    ".react-datepicker-popper": {
      zIndex: "2",
    },
    ".react-pdf": {
      width: "100% !important",
      height: "auto !important",
    },
  },
};

export default styles;
