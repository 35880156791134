import { FETCH_ENCOUNTER_SUCCESS, FETCH_ENCOUNTER_FAILURE } from "./actions";
import { LOGOUT } from "../authentication/actions";

const initialState = {
  hasFetched: false,
  encounter: null,
  encounterStatus: null,
  customPharmacy: null,
  hasDoctorRecommendations: false,
  hasPurchase: false,
  hasOTCPurchase: false,
  hasRxPurchase: false,
  hasId: null,
};

export default function encounter(state = initialState, action) {
  switch (action.type) {
    case FETCH_ENCOUNTER_SUCCESS:
      return {
        ...state,
        hasFetched: true,
        encounter: action.encounter,
        encounterStatus: action.encounterStatus,
        customPharmacy: action.customPharmacy,
        hasDoctorRecommendations: action.hasDoctorRecommendations,
        hasPurchase: action.hasPurchase,
        hasOTCPurchase: action.hasOTCPurchase,
        hasRxPurchase: action.hasRxPurchase,
        hasId: action.hasId,
      };
    case FETCH_ENCOUNTER_FAILURE:
      return {
        ...initialState,
        hasFetched: true,
      };
    // Clear data on logout
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
