// @refresh reset
// https://next.chakra-ui.com/docs/theming/component-style

// Common modifier styles includes:
// * Size: different sizes (e.g. small, medium, large)
// * Variant: different visual styles (e.g. outline, solid, ghost)
// * Color scheme: For a given variant, different color schemes
//    (e.g. an outline button with a red color scheme)
// * Color mode: change its visual styles based on color mode

// must be Sentence case
const Button = {
  baseStyle: {
    color: "black",
    fontFamily: "body",
    fontWeight: "700",
    textTransform: "uppercase",
    _hover: {
      textDecoration: "none",
    },
    _active: {
      outline: "none",
    },
    _focus: {
      outline: "none",
      boxShadow: "initial",
    },
    // this never seems to work, or at least it is not merged into a variant if if that variant contains a _disabled rule
    _disabled: {
      opacity: "1",
      cursor: "not-allowed",
    },
    // for some reason, these base styles don't show…
    sx: {
      // "& a": {
      //   color: "black",
      //   textDecoration: "none",
      // },
      "& .chakra-button__icon": {
        mt: "-2px",
        ml: "5px",
      },
      // …except this!
      "& [disabled]:hover": {
        bg: "crayola.900 !important",
      },
    },
  },
  sizes: {
    label: {
      fontSize: { base: "11px", md: "12px", lg: "12.5px" },
      h: { base: "32px", lg: "40px", xl: "44px" },
      px: "1.25em",
    },
    popover: {
      fontSize: { base: "13px", md: "14px", xl: "15px" },
      px: 0,
    },
    sm: {
      fontSize: { base: "10px", md: "11px", xl: "11px" },
      letterSpacing: "0.0125em",
      h: { base: "22px", lg: "24px" },
      px: { base: "6px", lg: "9px" },
    },
    smPlus: {
      fontSize: { base: "10px", md: "11px", xl: "11px" },
      letterSpacing: "0.0125em",
      h: { base: "32px", lg: "36px" },
      px: { base: "9px", lg: "14px" },
    },
    rg: {
      fontSize: { base: "12px", md: "13px", xl: "14px" },
      // letterSpacing: "0.0125em",
      h: { base: "32px", md: "40px" },
      pt: 0,
      px: "w5",
    },
    md: {
      fontSize: { base: "13px", md: "14px", xl: "15px" },
      h: { base: "40px", lg: "48px" },
      px: "w5",
    },
  },
  variants: {
    register: {
      borderRadius: "full", // or base?
      bg: "crayola.500",
      minW: { base: "100%", md: "140px" },
      ml: "-1px",
      _hover: {
        bg: "crayola.900",
      },
      // this never seems to work, or at least it is not merged into a variant if if that variant contains a _disabled rule
      _disabled: {
        bg: "var(--chakra-colors-crayola-500) !important",
        _hover: {
          bg: "var(--chakra-colors-crayola-500) !important",
        },
      },
    },
    borderless: {
      border: "0",
      borderRadius: "full",
      bg: "transparent",
      color: "black",
      _active: {
        background: "transparent",
      },
      _hover: {
        opacity: 0.7,
      },
    },
    outlineLight: {
      border: "1px solid",
      borderRadius: "full",
      borderColor: "blackAlpha.400",
      color: "blackAlpha.500",
      bg: "white",
      px: "w3",
      _hover: {
        bg: "white",
        borderColor: "black",
        // boxShadow: "black 0 0 0 1px",
        boxShadow: "0px 0px 0px 1px inset",
        color: "black",
      },
    },
    outline: {
      border: "1px solid",
      borderRadius: "full",
      borderColor: "black",
      bg: "white",
      _hover: {
        bg: "white",
        boxShadow: "0px 0px 0px 1px inset",
      },
    },
    capsule: (props) => ({
      borderRadius: "full",
      bg: `${props.colorScheme}.400`,
      _hover: {
        bg: `${props.colorScheme}.600`,
      },
    }),
    tablet: {
      border: "1px solid",
      borderRadius: "sm",
      borderColor: "black",
      bg: "white",
      color: "black",
      fontWeight: "600",
      h: "24px",
      pb: "1px",
      px: "5px",
      opacity: 0.4,
      _hover: {
        // https://rebassjs.org/button
        boxShadow: "0 0 0 1px inset",
        opacity: 1,
      },
    },
    hyperlink: {
      fontFamily: "body",
      fontWeight: "400",
      fontSize: "inherit",
      color: "uranianblue.1000",
      textDecoration: "none",
      textTransform: "none",
      cursor: "pointer",
      px: 0,
      h: "auto",
      _hover: {
        bg: "none",
        textDecoration: "underline",
      },
    },
    // mostly matches hMeta textStyle
    navLink: {
      fontFamily: "heading",
      fontWeight: "700",
      fontSize: { base: "11px", md: "12px", lg: "14px" },
      color: "uranianblue.1000",
      textDecoration: "none",
      letterSpacing: "0.025em",
      textTransform: "uppercase",
      borderRadius: "full",
      px: 0,
      _hover: {
        bg: "none",
        textDecoration: "underline",
      },
    },
    minor: {
      fontFamily: "body",
      fontWeight: "600",
      fontSize: { base: "11px", lg: "13px" },
      color: "uranianblue.900",
      bg: "white",
      textTransform: "uppercase",
      textDecoration: "none",
      border: "1px solid",
      borderColor: "currentColor",
      borderRadius: "sm",
      pt: "1px",
      _hover: {
        bg: "none",
      },
      _focus: {
        boxShadow: "0px 0px 0px 1px inset !important",
      },
    },
    // uses hMeta textStyle
    label: {
      fontFamily: "body",
      fontWeight: "700",
      letterSpacing: "0.025em",
      textTransform: "uppercase",
      bg: "transparent",
      _hover: {
        bg: "blackAlpha.100",
      },
      _disabled: {
        _hover: {
          bg: "var(--chakra-colors-black-alpha-100) !important",
        },
      },
      _focus: {
        boxShadow: "none",
      },
      _active: {
        bg: "blackAlpha.500",
        outline: "none",
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "register",
    // colorScheme: "crayola",
  },
};

export default Button;
