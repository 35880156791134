import * as api from "@/lib/api";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const LOGOUT = "LOGOUT";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";
export const SET_PROFILE_STATUS = "SET_PROFILE_STATUS";

/**
 * Login user using session cookies
 *
 * @param {object} credentials
 * @return {object} - server response
 */
export const loginUser = (credentials) => async (dispatch) => {
  const response = await api.login(credentials);

  if (response.error) {
    dispatch({
      type: LOGIN_FAILURE,
    });
    return response;
  }

  const { user } = response.json;
  dispatch({
    type: LOGIN_SUCCESS,
    user,
  });
  return user;
};

/**
 * Register a new user
 *
 * @param {object} credentials
 * @return {object} - server response
 */
export const registerUser = (credentials) => async (dispatch) => {
  const response = await api.register(credentials);

  if (response.error) {
    dispatch({
      type: REGISTER_FAILURE,
    });
    return response;
  }

  const { user } = response.json;
  dispatch({
    type: REGISTER_SUCCESS,
    user,
  });
  return user;
};

/**
 * Update user
 *
 * @param {object} credentials
 * @return {object} - server response
 */
export const updateUser = (settings) => async (dispatch) => {
  const response = await api.updateUserSettings(settings);

  if (response.error) {
    dispatch({
      type: USER_UPDATE_FAILURE,
    });
    return response;
  }

  const { user } = response.json;
  dispatch({
    type: USER_UPDATE_SUCCESS,
    user,
  });
  return user;
};

/**
 * Logout user from session
 */
export const logoutUser = () => async (dispatch) => {
  await api.logout();
  await dispatch({
    type: LOGOUT,
  });
};

/**
 * Delete account
 */
export const deleteAccount = () => async (dispatch) => {
  const response = await api.deleteUser();

  if (response.error) {
    dispatch({
      type: DELETE_ACCOUNT_FAILURE,
    });
    return response;
  }

  dispatch({
    type: DELETE_ACCOUNT_SUCCESS,
  });
  return response;
};

/**
 * Fetch the currently authed user from the server
 */
export const fetchUser = () => async (dispatch) => {
  const response = await api.getUser();

  if (response.error) {
    dispatch({
      type: FETCH_USER_FAILURE,
    });
    return response;
  }

  const { user } = response.json;
  dispatch({
    type: FETCH_USER_SUCCESS,
    user,
  });
  return user;
};

export const setProfileStatus = (status) => async (dispatch) => {
  return dispatch({
    type: SET_PROFILE_STATUS,
    status,
  });
};

export const selectors = {
  getUser: (state) => state.authentication.user,
  getHasFetchedUser: (state) => state.authentication.hasFetchedUser,
  getQuestionnaireAnswers: (state) =>
    state.authentication.user.questionnaireAnswers,
};
