/* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/foundations/index.ts */

import breakpoints from "./breakpoints";
import colors from "./colors";
import sizes from "./sizes";
import { spacing } from "./spacing";
import transition from "./transition";
import typography from "./typography";

const foundations = {
  breakpoints,
  colors,
  sizes,
  space: spacing,
  transition,
  ...typography,
};

type FoundationsType = typeof foundations;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Foundations extends FoundationsType {}

export default foundations;
