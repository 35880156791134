import Button from "./button";
import Checkbox from "./checkbox";
import FormLabel from "./form-label";
// import FormControl from "./form-control";
import Input from "./input";
import Select from "./select";
import Tabs from "./tabs";

const components = {
  Button,
  Checkbox,
  FormLabel,
  Input,
  Select,
  Tabs,
};

export default components;
