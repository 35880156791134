/**
 * Sets a value in localStorage if one isn't already set
 * @param {string} key
 * @param {string} value
 */
export function setLocalStorageIfNotSet(key, value) {
  if (localStorage) {
    const isItemAlreadySet = !!localStorage.getItem(key);
    if (!isItemAlreadySet) localStorage.setItem(key, value);
  }
}
