import PropTypes from "prop-types";
import { useRouter } from "next/router";
import Head from "next/head";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import Transition from "@/components/Transition";
import GoogleTagManager from "@/components/Analytics/AnalyticsGoogleTagManager";
import FacebookPixel from "@/components/Analytics/AnalyticsFacebookPixel";
import SentryWrapper from "@/components/SentryWrapper";
import theme from "@/theme";
import store from "@/store";
import "typeface-inter";
import "@/public/nprogress.css";
import useSavePortalInitialUtmSource from "@/hooks/useSavePortalInitialUtmSouce";

// use Global for @font-face b/c can't have multiple font-face keys
// within a theme.global.js object
// https://github.com/chakra-ui/chakra-ui/issues/1791#issuecomment-679051632
export default function App({ Component, pageProps }) {
  const router = useRouter();
  useSavePortalInitialUtmSource();

  // const isTransitionDisabled = sidebarLinks
  //   .map((link) => link.href)
  //   .includes(router.pathname);
  const isTransitionDisabled = true;

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" />
        {[16, 32, 96, 192].map((size) => (
          <link
            rel="icon"
            sizes="16x16"
            href={`https://www.datocms-assets.com/33954/1603970257-cleared.png?w=${size}&amp;h=${size}`}
            type="image/png"
            key={size}
          />
        ))}
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        {/* <meta
            name="google-site-verification"
            content="wfOsq57h3qCQUTbHcX-4qEEY07vgi4KgH9rdT1ywwwc"
          /> */}
        {/* <link
            rel="alternate"
            type="application/rss+xml"
            title="DatoCMS Blog"
            href="/blog.xml"
          />
          <link
            rel="alternate"
            type="application/rss+xml"
            title="DatoCMS Product Changelog"
            href="/product-changelog.xml"
          /> */}
        <title>Cleared Patient Portal</title>
      </Head>
      <Provider store={store}>
        <SentryWrapper>
          <ChakraProvider resetCSS theme={theme}>
            <Global
              styles={`
              @font-face {
                font-family: "SuisseIntl";
                font-display: swap;
                font-weight: 300;
                src: url("/fonts/SuisseIntl-Light.woff2") format("woff2"),
                url("/fonts/SuisseIntl-Light.woff") format("woff");
              }
              @font-face {
                font-family: "SuisseIntl";
                font-display: swap;
                font-weight: 300;
                font-style: italic;
                src: url("/fonts/SuisseIntl-LightItalic.woff2") format("woff2"),
                url("/fonts/SuisseIntl-LightItalic.woff") format("woff");
              }
              @font-face {
                font-family: "SuisseIntl";
                font-display: swap;
                font-weight: 500;
                src: url("/fonts/SuisseIntl-Medium.woff2") format("woff2"),
                url("/fonts/SuisseIntl-Medium.woff") format("woff");
              }
              @font-face {
                font-family: "SuisseIntl";
                font-display: swap;
                font-weight: 700;
                src: url("/fonts/SuisseIntl-Bold.woff2") format("woff2"),
                url("/fonts/SuisseIntl-Bold.woff") format("woff");
              }
              @font-face {
                font-family: "SuisseIntl";
                font-display: swap;
                font-weight: 700;
                font-style: italic;
                src: url("/fonts/SuisseIntl-BoldItalic.woff2") format("woff2"),
                url("/fonts/SuisseIntl-BoldItalic.woff") format("woff");
              }
              `}
            />
            <GoogleTagManager>
              <FacebookPixel>
                {isTransitionDisabled ? (
                  <Component {...pageProps} />
                ) : (
                  <Transition location={router.pathname}>
                    <Component {...pageProps} />
                  </Transition>
                )}
              </FacebookPixel>
            </GoogleTagManager>
          </ChakraProvider>
        </SentryWrapper>
      </Provider>
    </>
  );
}

// With getInitialProps set query params will be available on first render
// otherwise they're null initially
// Setting on `App` will affect all pages
// ensuring wizard navigation always works as expected
// Test pages & build before enabling
// App.getInitialProps = async () => ({});

App.propTypes = {
  Component: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageProps: PropTypes.object,
};
App.defaultProps = {
  pageProps: null,
};
