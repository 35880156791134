/**
 * Base functions for interacting with other apis
 *
 * Requests will return either a ApiResponse or ApiError (defined in @typedef below)
 * For more info see the request method
 *
 * @module ApiFactory
 */

/**
 * @typedef {Object} ApiError - The server APIs error format
 * @property {string} code - Errors unique identifier, used for errorToMessageMaps
 * @property {string} message - Human readable message
 */

/**
 * @typedef {Object} ApiResponse
 * @property {number} [status] - API response status
 * @property {object.<string, *>} [json] - API response data
 * @property {ApiError} [json.error] - Standard API error response
 * @property {ApiError} [error] - Either from the api or this module if the request failed to send
 *                                            Duplicates the json.error value for easy checking if the response errored
 *
 * There will always be either a `status` or `error`
 * You can expect `json` will always be set if the api response includes a body
 */

/**
 * @typedef {object} ApiErrorObject
 * @typedef {ApiError} ApiErrorObject.error
 */

/**
 * Construct an error object in the format of the servers errors response
 * @param {string} code
 * @param {string} message
 * @returns {ApiErrorObject}
 */
export function ApiError(code, message) {
  return {
    error: {
      code,
      message,
    },
  };
}

/**
 * API
 *
 * When replacing ApiFactory replace `res.error` with `res.json.error`
 * or the equivalent `res.data.error`
 *
 * @param {object} options
 * @param {string} options.url
 */
export default function API({ url: baseUrl }) {
  /**
   * Parse api response
   *
   * @private
   * @async
   *
   * @param {object} response
   * @returns {ApiResponse}
   */
  async function parseAPIResponse(response) {
    const contentType = response.headers.get("content-type");
    const isJson = contentType && contentType.includes("application/json");
    const res = {
      status: response.status,
    };

    if (isJson) {
      res.json = await response.json();

      if (res.json.error) {
        res.error = res.json.error;
      }
    }

    return res;
  }

  /**
   * Generic request template
   *
   * @private
   * @async
   *
   * @param {string} url
   * @param {object} data
   * @param {object} [options]
   * @param {string} [options.method]
   * @param {string} [options.formDataObject] - Is this request sending a JS FormData object
   * @returns {ApiResponse}
   */
  async function request(
    url,
    data,
    { method = "POST", formDataObject = false, cookie } = {}
  ) {
    const headers = {
      "Content-Type": "application/json",
    };
    // Let the browser decide the content type
    if (formDataObject) delete headers["Content-Type"];
    if (cookie) headers.cookie = cookie;

    const requestOptions = {
      method,
      headers: new Headers(headers),
      credentials: "include",
    };

    if (method !== "GET") {
      requestOptions.body = formDataObject ? data : JSON.stringify(data);
    }

    let response;
    try {
      response = await fetch(`${baseUrl}${url}`, requestOptions);
    } catch (error) {
      // Fetch throws in the case of a network error
      return ApiError("GENERIC", "Failed to communicate with the server");
    }

    return parseAPIResponse(response);
  }

  return Object.freeze({
    get: (url, data, options) =>
      request(url, data, { ...options, method: "GET" }),
    post: (url, data, options) =>
      request(url, data, { ...options, method: "POST" }),
    put: (url, data, options) =>
      request(url, data, { ...options, method: "PUT" }),
    delete: (url, data, options) =>
      request(url, data, { ...options, method: "DELETE" }),
    postFormData: (url, data, options) =>
      request(url, data, {
        ...options,
        method: "POST",
        formDataObject: true,
      }),
  });
}
