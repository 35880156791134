import * as api from "../../lib/api";

export const FETCH_ENCOUNTER_SUCCESS = "FETCH_ENCOUNTER_SUCCESS";
export const FETCH_ENCOUNTER_FAILURE = "FETCH_ENCOUNTER_FAILURE";

async function fetchEncounter(dispatch) {
  const response = await api.getEncounter();

  if (response.error) {
    dispatch({
      type: FETCH_ENCOUNTER_FAILURE,
    });
    return response;
  }

  const json = response.json;
  return dispatch({
    type: FETCH_ENCOUNTER_SUCCESS,
    ...json,
  });
}

/**
 * Login user using session cookies
 *
 * @param {object} credentials
 * @return {object} - server response
 */
export const loadEncounter = () => async (dispatch, getState) => {
  const state = getState();

  if (state.encounter.hasFetched) {
    return state.encounter;
  }

  return fetchEncounter(dispatch);
};

export const selectors = {
  getEncounter: (state) => state.encounter,
};
