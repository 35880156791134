// No need for @refresh reset anymore
// https://nextjs.org/docs/basic-features/fast-refresh

// Chakra layerStyles
// https://next.chakra-ui.com/docs/features/text-and-layer-styles

// NB. You can also switch layer styles
// function Example({ isSelected }) {
//   const layerStyle = isSelected ? "selected" : "base"
//   return <Box layerStyle={layerStyle}>This is a box</Box>
// }

// Not all props can be styled from here!
// Properties defined in a layer style:
// * Color or text color
// * Background color
// * Border width and border color
// * Box shadow
// * Opacity

import { inputH } from "@/theme/components/input";

const navH = { base: "65px", lg: "80px" };
const underNav = `var(--chakra-space-w5)`;
export const blockW = { base: "full", lg: "820px" };
export const mainW = "1440px";
export const asideW = "270px";
export const asideBg = "grey.10";

const layerStyles = {
  /*
    layouts
    ISSUE: px and maxW DO NOT WORK when used on <Container>,
    so use a <Box> instead…
    CF filed issue on Chakra GH: https://github.com/chakra-ui/chakra-ui/issues/3883
  */
  layoutProtected: {
    mx: "auto",
    w: "full",
    px: { lg: "insetLg" },
    pt: {
      // spaceUnderTop
      base: 2,
      lg: `calc(${underNav} + ${navH.lg})`,
    },
    pb: { base: 0, lg: "w4", xl: "w8" },
  },
  layoutProtectedFull: {
    w: "full",
    maxW: "full",
    mx: "0",
    px: "0",
  },
  mainContainer: {
    // maxW: "container.sm",
    // px: "insetLg", // clamps don't work on layerStyles?
    pt: "w6", // close to underNav, not required if child of LayoutProtected
    pb: "w9",
  },
  /* navbarAccountFixed */
  navAccountFixed: {
    pos: "fixed",
    top: {
      base: `calc(${underNav} + ${navH.base})`,
      lg: `calc(${underNav} + ${navH.lg} + 13px)`,
    },
    left: {
      base: "inset",
      sm: "inset",
      xl: "calc(50vw - 480px - 5vw)",
      xxxl: "calc(50vw - 480px - 3vw)",
    },
  },
  /* navbarSide */
  navbarSide: {
    height: {
      base: `calc(100% - ${navH.base})`,
      lg: `calc(100% - ${navH.lg})`,
    },
  },
  /* navbar utilities */
  navMinH: {
    h: navH,
  },
  mtNav: {
    mt: navH,
  },
  heightMinusNav: {
    height: {
      base: `calc(100vh - ${navH.base})`,
      lg: `calc(100vh - ${navH.lg})`,
    },
  },
  /* UNDER NAV */
  underNav: {
    pt: {
      base: 2,
      lg: `calc(${underNav} + ${navH.lg})`,
    },
  },
  /* This needs a better name as not clear what it does… */
  spaceUnderNav: {
    pt: {
      base: 0,
      lg: navH.lg,
    },
  },
  marginUnderNav: {
    mt: {
      base: navH.base,
      lg: navH.lg,
    },
  },
  spaceUnderTop: {
    pt: {
      base: navH.base,
      lg: `calc(${underNav} + ${navH.lg})`,
    },
  },
  marginUnderTop: {
    mt: {
      base: navH.base,
      lg: `calc(${underNav} + ${navH.lg})`,
    },
  },

  /* Input fields: used when the Chakra element is not <Input> */
  inputFieldRg: {
    fontSize: { base: "16px", md: "17px" },
    h: inputH,
    px: { base: 3, md: 4 },
    borderRadius: "md",
  },

  /* forms */
  formBox: {
    w: { md: 5 / 6, lg: 8 / 12 },
  },
  atkFormBox: {
    pt: "w3",
    pb: "w4",
  },
  formSubmitBox: {
    mt: { base: 4, lg: "w3" },
  },
  headerMessage: {
    textStyle: "textBody",
    color: "textblack",
    pt: 1,
    _first: { pt: "w2" },
  },
  formMessage: {
    textStyle: "textRg",
    fontWeight: "400",
    color: "textblack",
  },
  formSectionHeading: {
    textStyle: "textRg",
    fontWeight: "500",
    color: "textblack",
    pb: "w2",
  },
  /* buy box */
  buyBox: {
    boxShadow: "lg",
    border: "1px solid",
    borderColor: "blackAlpha.200",
    borderRadius: "md",
  },

  /* vstack reset */
  // vstackReset: {
  //   color: "salmon",
  //   // ---- nothing below here works, use a component! --------
  //   alignItems: "flex-start",
  //   ".chakra-stack &": {
  //     w: "full",
  //     color: "salmon",
  //   },
  //   sx: {
  //     "& .chakra-stack form": {
  //       w: "full",
  //       color: "salmon",
  //     },
  //   },
  // },
};

export default layerStyles;
