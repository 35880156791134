/**
 * @param {Object} router - next.js router
 * @param {Object} params
 */
function appendParams(router, params) {
  const searchParams = new URLSearchParams(router.query);

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
  }

  const queryString = searchParams.toString();
  const queryStringPrefixed = queryString ? `?${queryString}` : "";
  return `${router.pathname}${queryStringPrefixed}`;
}

export const pageView = (url) => {
  window.gtm1dataLayer.push({
    event: "pageview",
    page_path: url,
  });
};

export const pageViewWithParams = (router, params) => {
  const url = appendParams(router, params);
  pageView(url);
};

export const event = (data) => {
  window.gtm1dataLayer.push(data);
};

// Map productFieldObjects for GA ecommerce events
// See: https://www.notion.so/callum/Purchase-event-values-to-push-9e028d0fbb59412093c8a819653a5bda
const mapProducts = (products, coupon) => {
  return products.map((product) => {
    return {
      name: product.title,
      id: product.id,
      price: product.price,
      brand: product.genericName,
      category: product.productType,
      variant: product.handle,
      quantity: product.quantity,
      coupon: coupon,
    };
  });
};

export const ecommEvent = ({
  id,
  affiliation = "Shop",
  price,
  // tax // we don't need tax
  shipping,
  coupon,
  products,
}) => {
  window.gtm1dataLayer.push({
    event: "Purchase",
    ecommerce: {
      purchase: {
        actionField: {
          // Transaction ID required
          id: `cart-id-${id}`,
          affiliation: affiliation,
          // Total transaction value (incl. tax and shipping)
          revenue: price,
          // tax: tax,
          shipping: shipping,
          coupon: coupon,
        },
      },
      products: mapProducts(products),
    },
  });
};
