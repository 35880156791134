// @refresh reset
// https://chakra-ui.com/docs/theming/component-style

/*
  Input also requires parts
  See: https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/input.ts
  And: https://github.com/chakra-ui/chakra-ui/issues/2347#issuecomment-717804309
 */

export const inputH = { base: "48px", md: "54px" };

// must be Sentence case
const Input = {
  parts: ["field", "addon"],
  baseStyle: {
    field: {
      border: "1px solid",
      borderRadius: "base",
      transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0s",
      _hover: {
        bg: "uranianblue.200",
        borderColor: "uranianblue.800",
        color: "uranianblue.1000",
      },
      _focus: {
        bg: "uranianblue.200",
        borderColor: "uranianblue.800",
        color: "uranianblue.1000",
        boxShadow: "#28A6E4 0 0 0 1px",
      },
    },
  },
  sizes: {
    rg: {
      field: {
        fontSize: { base: "16px", md: "17px" },
        // retain use of height here as we don't want inputs with multi-lines, ie. so we don't use we don't use py "inputY" as ColorBox does
        h: inputH,
        px: { base: 3, md: 4 },
      },
    },
  },
  variants: {
    default: {
      field: {
        bg: "thiel.200",
        borderColor: "thiel.600",
        _placeholder: { color: "transparent" },
      },
    },
    profile: {
      field: {
        bg: "powderblue.100",
        borderColor: "blackAlpha.200",
        _placeholder: { color: "transparent" },
      },
    },
    // Styles replicate those of the textarea in /components/Form/Textarea.tsx
    quizMachine: {
      field: {
        textStyle: "textBody",
        borderRadius: "md",
        borderColor: "blackAlpha.300",
        _placeholder: {
          color: "blackAlpha.500",
        },
        _hover: {
          bg: "transparent",
          borderColor: "blackAlpha.500",
          outline: "none",
        },
        _focus: {
          bg: "transparent",
          borderColor: "blackAlpha.800",
          color: "black",
          boxShadow: "none",
          outline: "none",
        },
        _active: {
          borderColor: "blackAlpha.800",
          color: "black",
          boxShadow: "#28A6E4 0 0 0 1px",
          outline: "none",
        },
      },
    },
  },
  defaultProps: {
    size: "rg",
    variant: "default",
  },
};

export default Input;
