// @refresh reset
// https://next.chakra-ui.com/docs/features/text-and-layer-styles

// Properties defined in a text style
// * Font family, weight, and size
// * Line height
// * Letter spacing
// * Text decoration (strikethrough and underline)
// * Text transform (uppercase, lowercase, and capitalization)

const textStyles = {
  textXs: {
    fontSize: { base: "9px", md: "10px" },
    fontWeight: "medium",
    lineHeight: "base",
    textTransform: "initial",
    letterSpacing: "0.01em",
  },
  textSm: {
    fontSize: { base: "11px", md: "11px", xl: "12px" },
    lineHeight: "base",
    textTransform: "initial",
  },
  textRg: {
    fontSize: { base: "12px", md: "13.5px", xl: "15px" },
    lineHeight: "base",
    textTransform: "initial",
    letterSpacing: "-0.0075em",
  },
  textBody: {
    fontSize: { base: "14px", md: "15px", xl: "16px" },
    fontWeight: "normal",
    lineHeight: "base",
    textTransform: "initial",
    letterSpacing: "-0.0075em",
  },
  textMd: {
    fontSize: { base: "15px", md: "16px", xl: "17px" },
    fontWeight: "normal",
    lineHeight: "short",
    textTransform: "initial",
    letterSpacing: "-0.005em",
  },
  textLg: {
    fontSize: { base: "16px", md: "17px", xl: "18px" },
    fontWeight: "normal",
    lineHeight: "short",
    textTransform: "initial",
    letterSpacing: "-0.005em",
  },
  hLg: {
    fontSize: { base: "16px", md: "17px", xl: "18px" },
    fontWeight: "700",
    lineHeight: "shorter",
  },
  textXl: {
    fontSize: { base: "18px", md: "20px", lg: "21px" },
    fontWeight: "normal",
    lineHeight: "base",
    textTransform: "initial",
    letterSpacing: "-0.005em",
  },
  hXl: {
    fontFamily: "heading",
    fontSize: { base: "18px", md: "20px", xl: "21px" },
    fontWeight: "500",
    lineHeight: "shorter",
    letterSpacing: "-0.0075em",
  },

  text2xl: {
    fontSize: { base: "20px", md: "22px", xl: "24px" },
    fontWeight: "normal",
    lineHeight: "shorter",
    textTransform: "initial",
    letterSpacing: "-0.0075em",
  },
  heading2xl: {
    fontFamily: "heading",
    fontSize: { base: "20px", md: "22px", xl: "24px" },
    fontWeight: "700",
    lineHeight: "shortest",
    textTransform: "uppercase",
  },

  // TODO: merge or rename text3xl vs h3
  text3xl: {
    fontSize: { base: "21px", md: "24px", xl: "27px" },
    fontWeight: "normal",
    lineHeight: "shorter",
    textTransform: "initial",
    letterSpacing: "-0.0075em",
  },
  h3xl: {
    fontFamily: "heading",
    fontSize: { base: "21px", md: "24px", xl: "27px" },
    fontWeight: "500",
    lineHeight: "shortest",
    letterSpacing: "-0.0075em",
  },

  title: {
    fontFamily: "heading",
    fontSize: { base: "28px", md: "36px", xl: "40px" },
    fontWeight: "bold",
    lineHeight: "shortest",
    letterSpacing: "-0.01em",
    textTransform: "uppercase",
    ml: { base: "-1px", lg: "-1px" },
  },
  // TODO: merge or rename text4xl vs subtitle
  text4xl: {
    fontFamily: "heading",
    fontSize: { base: "28px", md: "32px", xl: "36px" },
    fontWeight: "bold",
    lineHeight: "none",
    letterSpacing: "-0.01em",
    // textTransform: "uppercase",
  },
  subtitle: {
    fontFamily: "heading",
    // fontSize: { base: "28px", md: "36px", xl: "40px" },
    fontSize: { base: "28px", md: "32px", xl: "36px" },
    fontWeight: "300",
    lineHeight: "shorter",
    letterSpacing: "-0.01em",
    ml: { base: "-1px", lg: "-1px" },
  },
  display: {
    fontFamily: "heading",
    fontSize: { base: "24px", md: "36px", xl: "48px" },
    fontWeight: "bold",
    lineHeight: "none",
    letterSpacing: "-0.0051em",
    textTransform: "uppercase",
  },

  badge: {
    fontFamily: "heading",
    fontWeight: "bold",
    letterSpacing: "-0.005em",
    lineHeight: 1,
  },
  hMeta: {
    // fontFamily: "heading",
    fontSize: { base: "10px", md: "11px", lg: "11px" },
    fontWeight: "700",
    lineHeight: "shorter",
    letterSpacing: "0.025em",
    textTransform: "uppercase",
  },
  button: {
    fontSize: { base: "11px", md: "13px", xl: "13px" },
    fontWeight: "600",
    lineHeight: "shorter",
    textTransform: "uppercase",
  },
  buttonMeta: {
    fontFamily: "body",
    fontSize: { base: "9px", md: "10px" },
    fontWeight: "500",
    lineHeight: "shorter",
    letterSpacing: "0.025em",
    textTransform: "uppercase",
  },
};

export default textStyles;
