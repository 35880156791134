// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import config from "@/config";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  environment: config.IS_PRODUCTION ? "production" : "staging",
  dsn:
    SENTRY_DSN ||
    "https://9818360af0604640b3e1db97335c3918@o1149906.ingest.sentry.io/5510353",

  // Enable for production mode
  beforeSend(event) {
    return config.NODE_ENV_IS_PRODUCTION ? event : null;
  },
  // Adjust this value in production, or use tracesSampler for greater control
  // tracesSampleRate: 1.0,
  tracesSampler() {
    // Only send traces in production,
    // only disabling traces keeps error reporting active in staging
    return config.IS_PRODUCTION ? 0.2 : 0;
  },

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
