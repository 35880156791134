import { useEffect } from "react";
import { useSelector } from "react-redux";
import * as authActions from "@/store/authentication/actions";
import * as Sentry from "@sentry/nextjs";

/**
 * Sentry Wrapper
 *
 * Identify users in sentry errors.
 * https://docs.sentry.io/platforms/javascript/guides/nextjs/enriching-events/identify-user/
 *
 * Whenever a user signs in or out the user is set in redux, this keeps sentry user details in sync with redux.
 * Must be below the redux provider.
 */
export default function SentryWrapper({ children }) {
  const user = useSelector(authActions.selectors.getUser);
  useEffect(() => {
    if (user) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  return children;
}
