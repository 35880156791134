/**
 * Collection of functions for interacting with other apis
 *
 * Functions in this file should only define an endpoint and and how to parse
 * the recieved data
 *
 * @module Api
 */

import API from "./apiFactory";
import config from "../config";
import {
  ICHSubmission,
  ICHSubmissionQuestion,
  IUserAddress,
  Product as ProductType,
  PharmacyAndHomeData,
  ITodo,
  IVeritasMedication,
} from "@/types";
import { ICheckoutTotals } from "@/types/Checkout.type";
import { ProductTypes } from "@/types/Product.type";
import { PharmacySearchResult } from "@/types/Pharmacy.type";
import getCookie from "./getCookie";

const api = API({
  url: config.serverUrl,
});

/**
 * Generic response types
 */
export interface IApiError {
  code: string;
  message: string;
}
interface ApiResponse<Response> {
  status?: number;
  json?: Response;
  error?: IApiError;
}

/**
 *
 * Utils
 *
 */

/**
 *
 * Auth
 *
 */

export async function login(credentials: { email: string; password: string }) {
  const initialUtmSource = getCookie(config.initialUtmSourceCookieName);
  const portalInitialUtmSource = localStorage.getItem(
    config.portalInitialUtmSouceLocalStorageName
  );

  return api.post("/api/auth/login", {
    ...credentials,
    initialUtmSource,
    portalInitialUtmSource: portalInitialUtmSource || undefined,
  });
}

/**
 * See: https://github.com/getcleared/cleared-patient-portal-server/blob/9f5de6d3ec63053365adb585f321f744936330e2/src/routes/auth/index.js#L123:L123
 */
export async function register(data: {
  isChildAccount: boolean;
  caregiverFirstName?: string;
  caregiverLastName?: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  state?: string;
  dateOfBirth: string;
  phoneNumber: string;
  gender: string;
  zip?: string;
  city?: string;
  address?: string;
}) {
  const initialUtmSource = getCookie(config.initialUtmSourceCookieName);
  const portalInitialUtmSource = localStorage.getItem(
    config.portalInitialUtmSouceLocalStorageName
  );
  return api.post("/api/auth/register", {
    ...data,
    initialUtmSource,
    portalInitialUtmSource: portalInitialUtmSource || undefined,
  });
}

export async function logout() {
  return api.delete("/api/auth/logout");
}

export async function getUser() {
  return api.get("/api/auth/user");
}

export async function verifyEmail(token: string) {
  return api.get(`/api/auth/verify-email?token=${token}`);
}

export async function isEmailTaken(email: string) {
  return api.post("/api/auth/email-taken", {
    email,
  });
}

export async function resendVerificationEmail(email: string) {
  return api.post("/api/auth/resend-verification-email", {
    email,
  });
}

export async function forgotPassword(email: string) {
  return api.post("/api/auth/forgot-password", {
    email,
  });
}

export async function resetPassword(token: string, password: string) {
  return api.post("/api/auth/reset-password", {
    token,
    password,
  });
}

/**
 *
 * User
 *
 */

export async function updateUserSettings(settings: Record<any, unknown>) {
  return api.post("/api/user/settings", settings);
}

export async function deleteUser() {
  return api.delete("/api/user/delete");
}

export async function setPharmacy(data: PharmacyAndHomeData) {
  return api.post("/api/user/pharmacy", data);
}

export async function setAddressDetails(data) {
  return api.post("/api/user/address-details", data);
}

export async function setDefaultPaymentMethod(data) {
  return api.post("/api/user/default-payment-method", data);
}

/**
 *
 * Identity
 *
 */

/**
 * @param formData - must include "id" value
 */
export async function uploadId(formData: FormData) {
  return api.postFormData("/api/identity/upload-id", formData);
}

/**
 * @param formData - must include "cardFront" & "cardBack"
 */
export async function uploadInsuranceCard(formData: FormData) {
  return api.postFormData("/api/identity/upload-insurance-card", formData);
}

/**
 * If the user chooses not to upload insurance ID the doctors need to be notified.
 */
export async function skipUploadingInsuranceId() {
  return api.post("/api/identity/skip-uploading-insurance-id");
}

/**
 *
 * Identity Crosscheck
 *
 */

interface VerifyIdentityResponse {
  // Verified means the user has uploaded photo ID or passed the crosscheck
  verified: boolean;
  // Will be undefined if the users identity has already been verified and therefore no crosscheck
  // was performed
  crosscheck?: {
    passed: boolean;
    confidenceScore: number;
  };
}
export async function performIdentityCrosscheck(): Promise<
  ApiResponse<VerifyIdentityResponse>
> {
  return api.get("/api/identity/verify-identity");
}

/**
 *
 * Stripe
 *
 */

export async function createStripeSetupIntent() {
  return api.get("/api/stripe/create-setup-intent");
}

/**
 *
 * Website Cart
 *
 */

export async function getCart(id: number) {
  return api.get(`/api/website/cart/${id}`);
}

interface GetShopCartResponse {
  id: number;
  stateId: string;
  interval: string;
  items: ProductType[];
  // Fixed to two decimal places
  total: string;
  totals: {
    couponSavings: number;
    couponCode: string;
    totalDueIfPrescribed: number;
    shippingFee: number;
  };
  address: IUserAddress;
}
export async function getWebsiteCart(
  cartId: number | string
): Promise<ApiResponse<GetShopCartResponse>> {
  return api.get(`/api/website/checkout/${cartId}`);
}

export async function getActiveWebsiteCartId() {
  return api.get("/api/website/checkout/active-cart-id");
}

export async function setWebsiteCartInterval(
  cartId: number | string,
  interval: string
) {
  return api.put(`/api/website/cart/${cartId}/interval`, {
    interval,
  });
}

export async function setWebsiteCartCouponCode(
  cartId: number | string,
  code: string
) {
  return api.put(`/api/website/cart/${cartId}/coupon-code`, {
    code,
  });
}

export async function removeWebsiteCartCouponCode(cartId: number | string) {
  return api.delete(`/api/website/cart/${cartId}/coupon-code`);
}

export async function checkoutWebsiteCart(cartId: number | string, data) {
  return api.post(`/api/website/checkout/${cartId}`, data);
}

export async function addToAbandonedCartList() {
  return api.post("/api/website/abandoned-cart-list");
}

/**
 *
 * ATK
 *
 */

export async function getKit(id: number) {
  return api.get(`/api/atk/${id}`);
}

export async function listKits() {
  return api.get("/api/atk");
}

export async function listOrders() {
  return api.get("/api/orders");
}

export async function cancelSubscription(id: string) {
  return api.delete(`/api/subscriptions/${id}`);
}

/**
 *
 * Quizzes
 *
 */

export const getQuiz = (quizId: string) => {
  return api.get(`/api/quiz/quizzes/${quizId}`);
};

export const getQuizzes = () => {
  return api.get("/api/quiz/quizzes");
};

/**
 * Record Quiz Submission
 */
export const saveQuizSubmission = async (
  submission: ICHSubmission
): Promise<{ error?: IApiError; id: string; identityId: string }> => {
  const initialUtmSource = getCookie(config.initialUtmSourceCookieName);
  const portalInitialUtmSource = localStorage.getItem(
    config.portalInitialUtmSouceLocalStorageName
  );
  const response = await api.post("/api/quiz/submissions", {
    initialUtmSource,
    portalInitialUtmSource: portalInitialUtmSource || undefined,
    ...submission,
  });

  return {
    error: response.json.error,
    id: response.json.id,
    identityId: response.json.identityId,
  };
};

/**
 * Tracks quiz progress
 */
export const trackQuizProgress = async (
  identityId: string,
  submissionId: string,
  questionnaireId: string,
  status: string | undefined,
  questions: ICHSubmissionQuestion[]
): Promise<void> => {
  await api.post("/api/quiz/page-partial-submission", {
    identityId,
    submissionId,
    questionnaireId,
    status,
    questions,
  });
};

/**
 * @param {object} body - either { submissionId: string} or { quizSlug: string }
 */
export const getSubmission = async (body: {
  // One of the two is required
  submissionId?: string;
  quizSlug?: string;
}) => {
  return api.post("/api/quiz/retrieve-submission/", body);
};

export const getProductRecommendations = async (data: {
  // One of the two is required
  submissionId?: string;
  quizSlug?: string;
}) => {
  return api.post("/api/quiz/product-recommendations", data);
};

/**
 * Associate a submission with the authed user
 */
export async function associateSubmission(
  submissionId: string,
  { completed = false }: { completed?: boolean } = {}
) {
  return api.post("/api/quiz/associate-submission", {
    submissionId,
    completed,
  });
}

/**
 * Set a quiz submission as ineligible
 */
export async function setSubmissionToIneligible(submissionId: string) {
  return api.post("/api/quiz/ineligible-submission", { submissionId });
}

export async function getRegistrationInfo(submissionId: string) {
  return api.post("/api/quiz/registration-info", { submissionId });
}

/**
 *
 * Quiz Carts
 *
 */

export async function createQuizCart(quizSlug: string) {
  return api.post("/api/quiz-cart/create", { quizSlug });
}

interface IQuizCartCheckoutResponse {
  stateId: string;
  interval: string;
  selected: ProductTypes[];
  recommended: ProductTypes[];
  totals: ICheckoutTotals;
  address: IUserAddress;
  pharmacyIsCarepoint: boolean;
}

export async function getQuizCartCheckout(
  quizSlug: string
): Promise<ApiResponse<IQuizCartCheckoutResponse>> {
  return api.get(`/api/quiz-cart/checkout?quizSlug=${quizSlug}`);
}

/**
 * Set items in cart
 * @param quizSlug
 * @param items - stripe product ids
 */
export async function setQuizCartItems(quizSlug: string, items: string[]) {
  return api.post("/api/quiz-cart/items", { quizSlug, items });
}

/**
 * Add items, keep existing items in cart
 * @param quizSlug
 * @param items - stripe product ids
 */
export async function addQuizCartItems(quizSlug: string, items: string[]) {
  return api.post("/api/quiz-cart/add-items", { quizSlug, items });
}

export async function setQuizCartInterval(quizSlug: string, interval: string) {
  return api.post("/api/quiz-cart/interval", { quizSlug, interval });
}

export async function setQuizCartCouponCode(
  quizSlug: string,
  couponCode: string
) {
  return api.post("/api/quiz-cart/coupon-code", { quizSlug, couponCode });
}

export async function removeQuizCartCouponCode(quizSlug: string) {
  return api.delete("/api/quiz-cart/coupon-code", { quizSlug });
}

export async function checkoutQuizCart({
  quizSlug,
  stateId,
  paymentMethod,
}: {
  quizSlug: string;
  stateId: string;
  paymentMethod: string;
}) {
  return api.post("/api/quiz-cart/checkout", {
    quizSlug,
    stateId,
    paymentMethod,
  });
}

/**
 *
 * Follow Up Carts
 *
 */

export async function getFollowUpCartCheckout(cartId: string) {
  return api.get(`/api/follow-up-cart/checkout?cartId=${cartId}`);
}

/**
 * Change the items in the cart to the new items
 * @param cartId
 * @param items - stripe product ids
 */
export async function addFollowUpCartItems(cartId: string, items: string[]) {
  return api.post("/api/follow-up-cart/items", { cartId, items });
}

export async function setFollowUpInterval(cartId: string, interval: string) {
  return api.post("/api/follow-up-cart/interval", { cartId, interval });
}

export async function setFollowUpCartCouponCode(
  cartId: string,
  couponCode: string
) {
  return api.post("/api/follow-up-cart/coupon-code", { cartId, couponCode });
}

export async function removeFollowUpCartCouponCode(cartId: string) {
  return api.delete("/api/follow-up-cart/coupon-code", { cartId });
}

export async function checkoutFollowUpCart({
  cartId,
  stateId,
  paymentMethod,
}: {
  cartId: string;
  stateId: string;
  paymentMethod: string;
}) {
  return api.post("/api/follow-up-cart/checkout", {
    cartId,
    stateId,
    paymentMethod,
  });
}

/*
 *
 * To-Dos
 *
 */

export async function getAllTodos(): Promise<
  ApiResponse<{ todos: Array<ITodo> }>
> {
  return api.get("/api/todos/all");
}

/*
 * Admin
 *
 */

export async function createFollowUpCheckout(
  email: string,
  veritasMedicationIds: Array<number>
): Promise<ApiResponse<undefined>> {
  return api.post(`/api/admin/create-follow-up-checkout`, {
    email,
    veritasMedicationIds,
  });
}

export async function getAllMedications(): Promise<
  ApiResponse<{ veritasMedications: Array<IVeritasMedication> }>
> {
  return api.get("/api/admin/medications");
}

/**
 * Pharmacies
 */

export async function searchPharmacies(
  zip: string,
  name: string
): Promise<ApiResponse<{ pharmacies: Array<PharmacySearchResult> }>> {
  return api.get(`/api/pharmacies/search?zip=${zip}&name=${name}`);
}
