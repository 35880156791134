// colours
// the Chakra color system begins at 500
// the tints down to 100 and shades up to 900
// Chakra Buttons use the 500 key as their primary solid colour
// So anything below 500 is a tint, anything above is a shade

// DEPRECATED: Coolors palette @21-07-23
// https://coolors.co/adddf5-facc52-e0eeec-a2b8ab-66a59b

// As of @21-12-15, we are using:
// https://components.ai/theme/h5GLs4AWMl4cl29CBOyQ
// …BUT only TealActive and Crayola have been updated to this palette

// body test is gray.800 WTF?

const colors = {
  // "grey" not "gray" so we can still use the inbuilt Chakra grays
  grey: {
    10: "#fafafa",
    50: "#f7f7f7",
    100: "#ececec",
    200: "#d5d5d5",
    // from Adomas, in between gray.600 & gray.700
    // FYI gray.800 #1A202C
    500: "#30424A",
  },
  blackgray: {
    50: "rgb(0 0 0 / 3%)",
    100: "rgb(0 0 0 / 6%)",
    200: "#d5d5d5",
    300: "#aaa",
    500: "#bbb",
  },
  textgray: "#8f989d", // near blackAlpha.500
  textblack: "#30424A", // near slate.700
  slate: {
    900: "#09090b",
    800: "#222127",
    700: "#3a3942",
    600: "#52525e",
    500: "#6b6a7a",
    400: "#858594",
    300: "#a2a1ae",
    200: "#bfbec7",
    100: "#dbdbe0",
    50: "#f8f8f9",
  },
  blueactive: {
    900: "#1c3647",
    800: "#23516d",
    700: "#286f96",
    600: "#298dc1",
    500: "#3baae5",
    400: "#6eb9ea",
    300: "#93c9ef",
    200: "#b5d8f4",
    100: "#d4e8f9",
    50: "#f3f9fd",
  },
  teal: {
    900: "#080d0d",
    800: "#1c302d",
    700: "#30534d",
    600: "#44756d",
    500: "#58988e",
    400: "#72b1a8",
    300: "#93c3bc",
    200: "#b3d5d0",
    100: "#d3e7e4",
    50: "#f4f9f8",
  },
  peach: {
    500: "#F4C4BD",
  },
  // blue powderblue
  // …but we use this as a green highlight!
  // https://coolors.co/b5e0dd
  powderblue: {
    50: "rgb(222 247 245)",
    100: "#fafbfc",
    // 200: "#C5D4EB",
    // 200: "rgb(203 218 217 / 20%)",
    200: "rgb(238 249 248)",
    // 300: "#EBEEF2",
    300: "rgb(181 224 222 / 20%)",
    400: "rgb(181 224 222 / 50%)",
    500: "rgb(181 224 222 / 70%)",
    600: "#b5e0dd", // rgb(181 224 222)
    700: "#48ADA6",
    800: "#22ada6",
    900: "#22ada6",
  },
  // pale green azure X11 aka "thiel" (bad legacy name!)
  // bad config as this 500 is a very pale green
  // 500s should be midpoints not tints
  // rename thiel > aquahaze? https://icolorpalette.com/color/E0EEEC
  // https://coolors.co/E0EEEC
  thiel: {
    50: "#fff",
    100: "#F2F8F7",
    200: "#EEF6F5",
    300: "#EAF3F2",
    400: "#E6F1EF",
    500: "#E0EEEC",
    // 600: "#8ba696",
    600: "#b7d6c4",
    // 700: "#6f917d",
    700: "#61A89D",
    800: "#52968C",
    900: "#33433a",
    1000: "#F7F7F7",
    1100: "#C5D4EB",
    1200: "#EBEEF2",
    1300: "#b5e0dd",
  },
  // green cambridgeblue
  // this is an olive green despite the name
  // https://coolors.co/a2b8ab
  tealgray: {
    50: "#f8faf9",
    // 100: "#E0EEEC",
    100: "#eaefec",
    200: "#dae3de",
    300: "#c9d6cf",
    400: "#b7c8be",
    500: "#a2b8ab",
    600: "#8ba696",
    700: "#6f917d",
    800: "#577262",
    900: "#33433a",
    1000: "#DDDDDD",
    1100: "#f9f9f9",
  },
  // green polished pine aka "tealactive"
  tealactive: {
    900: "#080d0d",
    800: "#1c302d",
    700: "#30534d",
    600: "#44756d",
    500: "#58988e",
    400: "#72b1a8",
    300: "#93c3bc",
    200: "#b3d5d0",
    100: "#d3e7e4",
    50: "#f4f9f8",
  },
  // blue uranianblue
  // https://coolors.co/adddf5
  uranianblue: {
    100: "#E8F5FC",
    200: "#D6EEFA",
    300: "#C5E7F8",
    400: "#BFE4F7",
    500: "#ADDDF5",
    600: "#97CFEB",
    700: "#5EBCEB",
    800: "#28A6E4",
    900: "#1A97D5",
    1000: "#1586BE",
  },
  // blue azure
  // https://coolors.co/2e7cf6
  azure: {
    100: "#A5C6FB",
    200: "#87B3FA",
    300: "#69A0F9",
    400: "#4B8DF7",
    500: "#2E7CF6",
    600: "#0A62EF",
    700: "#0852C7",
    800: "#07419F",
    900: "#053178",
  },
  // blue cornflower
  // https://coolors.co/97cfeb
  cornflower: {
    50: "#F3F9FD",
    100: "#F0F8FC",
    200: "#DAEEF8",
    300: "#C4E4F4",
    400: "#AEDAF0",
    500: "#97cfeb",
    600: "#97cfeb",
    700: "#97cfeb",
    800: "#97cfeb",
    900: "#97cfeb",
  },
  // yellow crayola
  crayola: {
    900: "#ebaf0b",
    800: "#efb61d",
    700: "#f2bd2e",
    600: "#f6c540",
    500: "#facc52",
    400: "#fad571",
    300: "#fadd91",
    200: "#fbe6b0",
    100: "#fbeed0",
    50: "#fbf7ef",
  },
  // form validation colours
  form: {
    good: "#38a169",
    // good: "#16DB93",
    goodShade: "rgba(56, 161, 105, 0.09)",
    bad: "#e53e3e",
    badShade: "rgba(229, 62, 62, 0.09)",
  },
  // quick fix for Todos tab
  bad: {
    500: "#e53e3e",
    600: "#e53e3e",
  },
  good: {
    500: "#38a169",
    600: "#38a169",
  },
};

export default colors;
